import React, {useState, useCallback} from 'react'
import {
    TextField,
    Icon,
    Modal,
    OptionList,
    Spinner,
    TextContainer,
} from "@shopify/polaris"
import { SearchIcon } from "@shopify/polaris-icons";
import update from 'immutability-helper'
import gql from "graphql-tag"
import { useLazyQuery } from 'react-apollo';
const GET_PRODUCT_TYPE = gql`
   {
    shop{
      productTypes(first: 200){
        edges{
          node
        }
      }
    }
  }
`
function ProductTypeModal(props) {
    const { saveExclusionValue, item, index } = props
    const [productTypeModalVisible, setProductTypeModalVisible] = useState(false)
    const [getProductTypes, productTypes] = useLazyQuery(GET_PRODUCT_TYPE);
    const handleProductTypeModalChange = () => {
        if( !productTypeModalVisible )
        {
            getProductTypes()
        }
        setProductTypeModalVisible(!productTypeModalVisible)
    }
    const saveProductType = (index) => {
      saveExclusionValue(index, selectedProductType)
      setProductTypeModalVisible(false)
    }
    const [selectedProductType, setSelectedProductType] = useState(item.value ? item.value : [])
    const [searchProductType, setSearchProductType] = useState(null)
    const handleSearchProductType = useCallback((newValue) => setSearchProductType(newValue), []);
    let searchedProductType = []
    if(productTypes.data){
        searchedProductType = productTypes.data.shop.productTypes.edges;
    }
    if(searchProductType){
        searchedProductType = productTypes.data.shop.productTypes.edges.filter(productType =>  productType.node.toLowerCase().match(searchProductType.toLowerCase()))
    }
  
    return (
                <Modal
                    activator={(<div className='mockInputContainer'>                                                        
                    <div className='mockInput'
                        onClick={handleProductTypeModalChange}
                        >{!item.value?
                        <span>Select Product Type</span>
                        :
                        <span>{item.value.length} types | Edit</span>
                        }
                    </div>
                    </div>)}
                    open={productTypeModalVisible}
                    onClose={handleProductTypeModalChange}
                    title="Select a product type"
                    primaryAction={{
                    content: 'Save',
                    onAction: () => saveProductType(index),
                    }}
                    secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleProductTypeModalChange,
                    },
                    ]}
                >
                    <Modal.Section>
                    <TextContainer>
                        {(productTypes.loading && !productTypes.data) ?
                        <Spinner accessibilityLabel="Loading Product Type" size="large" />
                        :
                        productTypes.data ?
                        <div>
                          <TextField
                            label="Search Product Type"
                            labelHidden
                            prefix={<Icon
                                source={SearchIcon}
                                tone="base" />}
                            autoComplete="off"
                            value={searchProductType}
                            onChange={handleSearchProductType}
                            />
                            <OptionList
                                title="Available Product Type"
                                onChange={setSelectedProductType}
                                options={
                                    searchedProductType.map(productType => 
                                {return {value: productType.node, label: productType.node}}
                                )
                                }
                                selected={selectedProductType}
                                allowMultiple
                            />
                        </div>
                        :
                        false
                        }
                    </TextContainer>
                    </Modal.Section>
                </Modal>        
        
    )
}

export default ProductTypeModal;